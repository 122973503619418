import { OptionsInterface } from '../components/projects/DatastoreFilterPopover';
import DeploymentOptions from './DeploymentOptions';
import DeploymentsItem, { DeploymentsItemClassProps } from './DeploymentsItem';

export interface DeploymentsInterface {
    deployments: DeploymentsItem[];
}

export default class Deployments implements DeploymentsInterface {
    readonly deployments: DeploymentsItem[];

    constructor(
        clusters: DeploymentsItemClassProps[],
        deploymentOptions: DeploymentOptions | undefined
    ) {
        this.deployments = clusters.map(
            (r: any) => new DeploymentsItem(r, deploymentOptions)
        );
    }

    isAnyCritical() {
        return this.getAllCritical().length > 0;
    }

    isAnyWarning() {
        return this.getAllWarning().length > 0;
    }

    areAllGood() {
        return this.getAllGood().length === this.deployments.length;
    }

    map(f: any) {
        return this.deployments.map(f);
    }

    filter(f: any) {
        return this.deployments.filter(f);
    }

    length() {
        return this.deployments.length;
    }

    notDeletingLength() {
        return this.getNotDeleting().length;
    }

    getByUuid(dataStoreUuid: string) {
        const filtered = this.deployments.filter((d: any) => {
            return d.dataStoreUuid === dataStoreUuid;
        });
        if (filtered && filtered.length > 0) {
            return filtered[0];
        }
        return null;
    }

    protected getAllCritical() {
        return this.deployments.filter((d: DeploymentsItem) => {
            return d.isStatusError();
        });
    }

    protected getAllWarning() {
        return this.deployments.filter((d: DeploymentsItem) => {
            return d.isStatusWarning();
        });
    }

    protected getAllGood() {
        return this.deployments.filter((d: DeploymentsItem) => {
            return d.isStatusOk() || d.isDeleting();
        });
    }

    protected getNotDeleting() {
        return this.deployments.filter((d: DeploymentsItem) => {
            return (
                d.isStatusOk() ||
                d.isStatusError() ||
                d.isStatusWarning() ||
                d.isDeploying()
            );
        });
    }

    public getVendorsForFiltering() {
        return [
            {
                label: 'SQL Server',
                value: 'microsoft',
            },
            {
                label: 'MySQL',
                value: 'percona',
            },
            {
                label: 'PostgreSQL',
                value: 'postgres',
            },
            {
                label: 'Redis',
                value: 'redis',
            },
            {
                label: 'MariaDB',
                value: 'mariadb',
            },
        ] as OptionsInterface[];
    }

    public getTagsForFiltering() {
        return Array.from(
            new Set(
                this.deployments.map((deployment) => deployment.tags).flat()
            )
        ).map((tag) => ({ label: tag, value: tag }));
    }
}
