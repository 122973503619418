import { ReactElement, useEffect } from 'react';
import { Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Deployments from '../../types/Deployments';
import Deployment from '../../types/Deployment';
import Databases from './Databases';
import DatabaseGrowth from './DatabaseGrowth';

interface UrlProps {
    dataStoreUuid: string;
    projectUuid: string;
    tab: string;
}

type DbDatabaseProps = {
    deployments?: Deployments;
    deployment?: Deployment;
};

function DbDatabases({ deployments }: DbDatabaseProps): ReactElement {
    const { dataStoreUuid, projectUuid, tab } = useParams<UrlProps>();
    const currentDeployment = deployments?.getByUuid(dataStoreUuid);

    const history = useHistory();

    useEffect(() => {
        if (currentDeployment?.isRedis()) {
            history.goBack();
        }
    }, [currentDeployment]);

    return (
        <section>
            <Tabs
                data-testid="DatabasesTabs"
                activeKey={tab || 'dbDatabases'}
                onChange={(tabKey: any) => {
                    const tab = tabKey === 'dbDatabases' ? '' : `/${tabKey}`;

                    history.push(
                        `/projects/${projectUuid}/data-stores/${dataStoreUuid}/dbDatabases${tab}`
                    );
                }}
            >
                <Tabs.TabPane
                    tab="Databases"
                    key="dbDatabases"
                    style={{ paddingTop: 20 }}
                >
                    <Databases deployments={deployments} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab="Database Growth"
                    key="growth"
                    style={{ paddingTop: 20 }}
                >
                    <DatabaseGrowth deployments={deployments} />
                </Tabs.TabPane>
            </Tabs>
        </section>
    );
}

export default DbDatabases;
