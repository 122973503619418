import { singularOrPlural } from '../core/helpers';
import DbService from './DbService';

export type JobClassProps = {
    data: any;
    job_id: string;
    user: string;
    status: string;
    type: string;
    messages: string[];
    text: string;
    created_at: string;
    finished_at: string;
    started_at: string;
};

export interface JobInterface {
    data: any;
    jobId: string;
    status: string;
    type: string;
    messages: string[];
    finishedAt: string;
    createdAt: string;
    startedAt: string;
}

export const STATUS_OK = 'JOB_STATUS_CREATED';
export const STATUS_ERROR = 'JOB_STATUS_ERRORED';
export const STATUS_RUNNING = 'JOB_STATUS_RUNNING';
export const STATUS_FINISHED = 'JOB_STATUS_FINISHED';

export const TYPE_ADD = 'JOB_TYPE_ADD_NODE';
export const TYPE_REMOVE = 'JOB_TYPE_REMOVE_NODE';
export const TYPE_VOLUME_SIZE = 'JOB_TYPE_VOLUME_SIZE';
export const TYPE_PROMOTE_REPLICA = 'JOB_TYPE_PROMOTE_REPLICA';
export const TYPE_UPGRADE_SERVERS = 'JOB_TYPE_UPGRADE_SERVERS';
export const TYPE_WORK = 'JOB_TYPE_WORK';
export const TYPE_MODIFYDBCONFIG = 'JOB_TYPE_MODIFYDBCONFIG';
export const TYPE_DEPLOY_DATASTORE = 'JOB_TYPE_DEPLOY_DATASTORE';
export const TYPE_DESTROY_DATASTORE = 'JOB_TYPE_DESTROY_DATASTORE';
export const TYPE_REPAIR_NODE = 'JOB_TYPE_REPAIR_NODE';
export const TYPE_RESTORE_BACKUP = 'JOB_TYPE_RESTORE_BACKUP';
export const TYPE_CHANGE_VOLUME_TYPE = 'JOB_TYPE_CHANGE_VOLUME_TYPE';
export const JOB_TYPE_CHANGE_VOLUME_TYPE = 'JOB_TYPE_CHANGE_VOLUME_TYPE';
export default class Job implements JobInterface {
    readonly createdAt: string;
    readonly data: any;
    readonly finishedAt: string;
    readonly jobId: string;
    readonly startedAt: string;
    readonly user: string;
    readonly status: string;
    readonly type: string;
    readonly text: string;
    readonly messages: string[];

    constructor(props: JobClassProps) {
        this.data = props.data;
        this.finishedAt = props.finished_at;
        this.jobId = props.job_id;
        this.startedAt = props.started_at;
        this.user = props.user;
        this.status = props.status;
        this.type = props.type;
        this.text = props.text;
        this.messages = props.messages || [];
        this.createdAt = props.created_at;
    }

    isStatusOk() {
        return this.status.toUpperCase() === STATUS_OK;
    }

    isStatusError() {
        return this.status.toUpperCase() === STATUS_ERROR;
    }

    isStatusRunning() {
        return this.status.toUpperCase() === STATUS_RUNNING;
    }

    isStatusFinished() {
        return this.status.toUpperCase() === STATUS_FINISHED;
    }

    isAddNode() {
        return this.type.toUpperCase() === TYPE_ADD;
    }

    isRemoveNode() {
        return this.type.toUpperCase() === TYPE_REMOVE;
    }

    isVolumeScale() {
        return this.type.toUpperCase() === TYPE_VOLUME_SIZE;
    }

    getStatusText(): string {
        switch (this.status.toUpperCase()) {
            case STATUS_RUNNING:
                return 'Running';
            case STATUS_ERROR:
                return 'Failed';
            case STATUS_OK:
                return 'Created';
            case STATUS_FINISHED:
                return 'Finished';
            default:
                return 'Unknown';
        }
    }

    getStatusType(): string {
        switch (this.status.toUpperCase()) {
            case STATUS_RUNNING:
                return 'info';
            case STATUS_ERROR:
                return 'error';
            case STATUS_OK:
                return 'normal';
            case STATUS_FINISHED:
                return 'ok';
            default:
                return 'normal';
        }
    }

    getRunningStatusTextByType(): string | null {
        switch (this.type.toUpperCase()) {
            case TYPE_VOLUME_SIZE:
                return 'Scaling storage...';
            case TYPE_PROMOTE_REPLICA:
                return 'Promoting replica...';
            case TYPE_ADD:
            case TYPE_REMOVE:
                return 'Scaling nodes...';
            case TYPE_UPGRADE_SERVERS:
                return 'Upgrading servers...';
            case TYPE_WORK:
                return 'Working on store...';
            case TYPE_MODIFYDBCONFIG:
                return 'Setting parameters...';
            case TYPE_REPAIR_NODE:
                return 'Repairing node...';
            case TYPE_RESTORE_BACKUP:
                return 'Restoring backup...';
            case TYPE_CHANGE_VOLUME_TYPE:
                return 'Changing volume type...';
            default:
                return null;
        }
    }

    getDescription(relatedNode: DbService) {
        const descriptions: any = {
            [TYPE_ADD]: (() => {
                const nodesAdded = this.data?.ToAdd?.length;
                const name = singularOrPlural(nodesAdded, `node`);

                return {
                    [STATUS_OK]: `Adding ${nodesAdded} new ${name}`,
                    [STATUS_ERROR]: `An error occurred while adding ${nodesAdded} new ${name}`,
                    [STATUS_RUNNING]: `Adding ${nodesAdded} new ${name}`,
                    [STATUS_FINISHED]: `Added ${nodesAdded} new ${name} successfully`,
                };
            })(),
            [TYPE_REMOVE]: (() => {
                const nodesRemoved = this.data?.ToRemove?.length;
                const name = singularOrPlural(nodesRemoved, `node`);

                return {
                    [STATUS_OK]: `Removing ${nodesRemoved} ${name}`,
                    [STATUS_ERROR]: `An error occurred while removing ${nodesRemoved} ${name}`,
                    [STATUS_RUNNING]: `Removing ${nodesRemoved} ${name}`,
                    [STATUS_FINISHED]: `Removed ${nodesRemoved} ${name} successfully`,
                };
            })(),
            [TYPE_VOLUME_SIZE]: (() => {
                const newSize = `${this.data?.NewSize}GB`;

                return {
                    [STATUS_OK]: `Updating volume size to ${newSize}`,
                    [STATUS_ERROR]: `An error occurred while updating volume size to ${newSize}`,
                    [STATUS_RUNNING]: `Updating volume size to ${newSize}`,
                    [STATUS_FINISHED]: `Volume size updated to ${newSize} successfully`,
                };
            })(),
            [TYPE_PROMOTE_REPLICA]: (() => {
                const nodeName = `${relatedNode?.getHostnameOrIP()}`;

                return {
                    [STATUS_OK]: `Replica node (${nodeName}) is being promoted`,
                    [STATUS_ERROR]: `An error occurred while promoting replica node (${nodeName})`,
                    [STATUS_RUNNING]: `Replica node (${nodeName}) is being promoted`,
                    [STATUS_FINISHED]: `Replica node (${nodeName}) promoted successfully`,
                };
            })(),
            [TYPE_UPGRADE_SERVERS]: {
                [STATUS_OK]: `Upgrading servers`,
                [STATUS_ERROR]: `An error occurred while upgrading servers`,
                [STATUS_RUNNING]: `Upgrading servers`,
                [STATUS_FINISHED]: `Servers upgraded successfully`,
            },
            [TYPE_CHANGE_VOLUME_TYPE]: {
                [STATUS_OK]: `Changing volume type`,
                [STATUS_ERROR]: `An error occurred while changing volume type`,
                [STATUS_RUNNING]: `Changing volume type`,
                [STATUS_FINISHED]: `Volume type change successfully`,
            },
            [TYPE_MODIFYDBCONFIG]: {
                [STATUS_OK]: `Updating database configuration`,
                [STATUS_ERROR]: `An error occurred while updating database configuration`,
                [STATUS_RUNNING]: `Updating database configuration`,
                [STATUS_FINISHED]: `Database configuration updated successfully`,
            },
            [TYPE_DEPLOY_DATASTORE]: {
                [STATUS_OK]: `Deploying datastore`,
                [STATUS_ERROR]: `An error occurred while deploying datastore`,
                [STATUS_RUNNING]: `Deploying datastore`,
                [STATUS_FINISHED]: `Datastore deployed successfully`,
            },
            [TYPE_DESTROY_DATASTORE]: {
                [STATUS_OK]: `Destroying datastore`,
                [STATUS_ERROR]: `An error occurred while destroying datastore`,
                [STATUS_RUNNING]: `Destroying datastore`,
                [STATUS_FINISHED]: `Datastore destroyed`,
            },
            [TYPE_REPAIR_NODE]: {
                [STATUS_OK]: `Repairing node`,
                [STATUS_ERROR]: `An error occurred while repairing node`,
                [STATUS_RUNNING]: `Repairing node`,
                [STATUS_FINISHED]: `Node repaired successfully`,
            },
            [TYPE_RESTORE_BACKUP]: (() => {
                const backupId = this.data?.restore?.cmon_backup_id;
                return {
                    [STATUS_OK]: `Restoring backup (${backupId})`,
                    [STATUS_ERROR]: `An error occurred while restoring backup (${backupId})`,
                    [STATUS_RUNNING]: `Restoring backup (${backupId})`,
                    [STATUS_FINISHED]: `Backup (${backupId}) restored successfully`,
                };
            })(),
        };

        const forType = descriptions[this.type.toUpperCase()];
        if (forType) {
            return forType[this.status.toUpperCase()];
        }

        return this.text || this.data.text || this.type;
    }

    getDateForEventViewer() {
        if (this.isStatusFinished()) return this.finishedAt;
        if (this.isStatusRunning() || this.isStatusError())
            return this.startedAt;

        return this.createdAt;
    }
}
