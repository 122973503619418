import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Deployments from '../../types/Deployments';
import CcxComponentProps from '../../core/CcxComponent';
import styles from './ProjectBreadcrumb.module.less';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { BASE_PAGE_HEADING, hideBreadcrumbs } from '../../core/CcxEnv';

interface UrlProps {
    projectUuid: string;
    dataStoreUuid: string;
    vpcUuid: string;
}
interface Props extends CcxComponentProps {
    deployments?: Deployments;
}

function ProjectBreadcrumb({
    deployments,
    testId = 'ProjectBreadcrumb',
}: Props): ReactElement {
    const history = useHistory();
    const { projectUuid, dataStoreUuid } = useParams<UrlProps>();
    const [name, setName] = useState<string | undefined>('');
    const [vendor, setVendor] = useState<string | undefined>('');
    const [version, setVersion] = useState<string | undefined>('');

    useEffect(() => {
        if (deployments) {
            const currentDeployment = deployments?.getByUuid(dataStoreUuid);
            setVersion(currentDeployment?.db?.dbVersion);
            setVendor(currentDeployment?.getDbVendorName());
            setName(currentDeployment?.getTruncatedName());
        }
    }, [deployments]);

    return (
        <div className={styles.ProjectBreadcrumb} data-testid={testId}>
            {!hideBreadcrumbs && (
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={`/projects/${projectUuid}/data-stores`}>
                            {BASE_PAGE_HEADING}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{name}</Breadcrumb.Item>
                </Breadcrumb>
            )}
        </div>
    );
}

export default ProjectBreadcrumb;
