import DatabaseVendorType, {
    DatabaseVendorTypeClassProps,
} from './DatabaseVendorType';

export const DB_VENDOR_MARIADB = 'mariadb';
export const DB_VENDOR_POSTGRESQL = 'postgres';
export const DB_VENDOR_PERCONA = 'percona';
export const DB_VENDOR_REDIS = 'redis';
export const DB_VENDOR_MSSQL = 'microsoft';

export type DatabaseVendorClassProps = {
    beta: boolean;
    code: string;
    enabled: boolean;
    info: string;
    name: string;
    num_nodes: number[];
    type: string;
    types: any;
    version: string;
    versions: string[];
};

export interface DatabaseVendorInterface {
    beta: boolean;
    code: string;
    enabled: boolean;
    info: string;
    name: string;
    numberOfNodes: number[];
    type: string;
    types: any;
    version: string;
    versions: string[];

    getVendorNameWithVersion: Function;
    isPostgreSql: Function;
    isMariadb: Function;
    isPercona: Function;
    isRedis: Function;
    isBeta: Function;
    isDisabled: Function;
    getVendorTypes: Function;
    getVendorTypeNameByCode: Function;
}

export default class DatabaseVendor implements DatabaseVendorInterface {
    readonly beta: boolean;
    readonly code: string;
    readonly enabled: boolean;
    readonly info: string;
    readonly name: string;
    readonly numberOfNodes: number[];
    readonly type: string;
    readonly types: any;
    readonly version: string;
    readonly versions: string[];

    constructor(props: DatabaseVendorClassProps) {
        this.beta = props.beta;
        this.code = props.code;
        this.enabled = props.enabled;
        this.info = props.info;
        this.name = props.name;
        this.numberOfNodes = props.num_nodes;
        this.type = props.type;
        this.types = props.types.map((v: DatabaseVendorTypeClassProps) => {
            return new DatabaseVendorType(v);
        });
        this.version = props.version;
        this.versions = props.versions;
    }

    getVendorNameWithVersion(): string {
        return `${this.name} ${this.version}`;
    }

    isPostgreSql(): boolean {
        return this.code === DB_VENDOR_POSTGRESQL;
    }

    isMariadb(): boolean {
        return this.code === DB_VENDOR_MARIADB;
    }

    isPercona(): boolean {
        return this.code === DB_VENDOR_PERCONA;
    }

    isRedis(): boolean {
        return this.code === DB_VENDOR_REDIS;
    }

    isMssql(): boolean {
        return this.code === DB_VENDOR_MSSQL;
    }

    isBeta(): boolean {
        return this.beta;
    }

    isDisabled(): boolean {
        return this.enabled === false;
    }

    getVendorTypes(): any[] {
        return this.types;
    }

    getSortedVendorTypes(): DatabaseVendorType[] {
        const priorityOrder: string[] = [
            'redis',
            'postgres_streaming',
            'replication',
            'mssql_single',
        ];
        const customSort = (
            a: DatabaseVendorType,
            b: DatabaseVendorType
        ): number => {
            const indexA = priorityOrder.indexOf(a.code);
            const indexB = priorityOrder.indexOf(b.code);

            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
            } else if (indexA !== -1) {
                return -1;
            } else if (indexB !== -1) {
                return 1;
            } else {
                return a.code.localeCompare(b.code);
            }
        };

        return this.types.sort(customSort);
    }
    getVendorTypeByCode(code: string): DatabaseVendorType {
        const type = this.getVendorTypes().find(
            (t: DatabaseVendorType) => t.code === code
        );

        return type;
    }

    getSelectedNode(code: string): number | undefined {
        const vendorType: DatabaseVendorType = this.getVendorTypeByCode(code);
        if (vendorType) {
            const sizeHintsKeys = Object.keys(vendorType.sizeHints);
            if (sizeHintsKeys.length === 1) {
                return parseInt(sizeHintsKeys[0], 10);
            }
        }
        return undefined;
    }
    getVendorTypeNameByCode(code: string): string {
        const type = this.getVendorTypes().find(
            (t: DatabaseVendorType) => t.code === code
        );

        return type?.name || 'N/A';
    }

    getVendorTypeName(code: string): string {
        const type = this.getVendorTypes().find(
            (t: DatabaseVendorType) => t.code === code
        );
        if (type?.name.includes('Master')) {
            return type?.name?.replace('Master', 'Primary');
        } else {
            return type?.name || 'N/A';
        }
    }
}
