import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Deployments from '../types/Deployments';
import DataStoreService from '../services/DataStoresService';
import ContentService from '../services/ContentService';
import { objectToQueryParams } from '../core/helpers';

interface DatastoreType {
    datastores: Deployments | undefined;
    totalItems: number;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
}
const initialState = {
    datastores: undefined,
    totalItems: 1,
    isError: false,
    isSuccess: false,
    isLoading: false,
} as DatastoreType;

export type GetAllDataStoresParams =
    | undefined
    | {
          page: number;
          per_page: number;
          tags: string[];
          db_vendors: string[];
      };

export const getAllDataStores = createAsyncThunk(
    'datastores/getDatastores',
    async (params: GetAllDataStoresParams, { getState }: any) => {
        try {
            const paramString: string = params
                ? objectToQueryParams(params)
                : '';
            const { deploymentOptions } = getState()?.deploymentOptions;
            const response = await DataStoreService.getDataStores(
                'default',
                deploymentOptions,
                paramString
            );
            return response;
        } catch (e) {
            console.error(e);
        }
    }
);

export const userSlice = createSlice({
    name: 'datastores',
    initialState,
    reducers: {
        getAllDataStores: (state, action) => {
            state.isLoading = false;
            state.datastores = action.payload?.datastores;
            state.totalItems = action.payload?.totalItems;
            state.isSuccess = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDataStores.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllDataStores.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.datastores = action.payload?.datastores;
                state.totalItems = action.payload?.totalItems;
            })
            .addCase(getAllDataStores.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default userSlice.reducer;
