export type CloudProviderRegionClassProps = {
    code: string;
    name: string;
    country_code: string;
    continent_code: string;
    city: string;
    display_code: string;
    preferred_display_code: string;
};

export interface CloudProviderRegionInterface {
    code: string;
    name: string;
    countryCode: string;
    continentCode: string;
    displayCode: string;
    city: string;
}

export default class CloudProviderRegion
    implements CloudProviderRegionInterface
{
    readonly code: string;
    readonly name: string;
    readonly countryCode: string;
    readonly continentCode: string;
    readonly city: string;
    readonly displayCode: string;
    readonly preferredDisplayCode: string;

    constructor(props: CloudProviderRegionClassProps) {
        this.code = props.code;
        this.name = props.name;
        this.countryCode = props.country_code.toLowerCase();
        this.continentCode = props.continent_code;
        this.city = props.city;
        this.displayCode = props.display_code;
        this.preferredDisplayCode = props.preferred_display_code;
    }
}
