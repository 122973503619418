import React, { ReactElement, useEffect } from 'react';
import { Dropdown, Space, Typography, Badge, Button, Menu } from 'antd';
import { useParams } from 'react-router';
import CcxComponentProps from '../../core/CcxComponent';
import useDataStoreJobs from '../../core/hooks/useDataStoreJobs';
import Job from '../../types/Job';
import CcxIconBellOutlined from '../ccx/icons/CcxIconBellOutlined';
import styles from './AppHeaderNotifications.module.less';
import AppNotificationItem from './AppNotificationItem';
import { Divider } from 'antd';

interface Props extends CcxComponentProps {}

interface UrlProps {
    dataStoreUuid?: string;
}

function AppHeaderNotifications({
    testId = 'AppHeaderNotifications',
}: Props): ReactElement {
    const { Text } = Typography;

    const { dataStoreUuid } = useParams<UrlProps>();
    const { jobs } = useDataStoreJobs({
        dataStoreUuid,
    });

    const menu = (
        <Menu
            data-testid={`${testId}Menu`}
            className={styles.AppHeaderNotificationsMenu}
        >
            <div className={styles.AppHeaderNotificationsTitle}>
                <Text type="secondary"> Latest notifications</Text>
            </div>

            {jobs?.map((job: Job) => {
                return (
                    <React.Fragment key={`${job.jobId}Frag`}>
                        <Divider
                            className={styles.AppHeaderNotificationsDivider}
                        />
                        <Menu.Item key={job.jobId}>
                            <AppNotificationItem notification={job} />
                        </Menu.Item>
                    </React.Fragment>
                );
            })}
        </Menu>
    );

    return dataStoreUuid && jobs && jobs.length > 0 ? (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomCenter"
            className={styles.AppHeaderNotifications}
            data-testid={testId}
        >
            <Button type="link" data-testid="AppHeaderNotificationsButton">
                <Space size={8}>
                    {jobs?.length > 0 ? (
                        <Badge
                            dot={true}
                            className={styles.AppHeaderNotificationsBadge}
                        >
                            <CcxIconBellOutlined testId="AppHeaderNotificationsIcon" />
                        </Badge>
                    ) : (
                        <CcxIconBellOutlined testId="AppHeaderNotificationsIcon" />
                    )}
                </Space>
            </Button>
        </Dropdown>
    ) : (
        <></>
    );
}

export default AppHeaderNotifications;
